// @ts-check

import 'diggle-eden/style.scss'

import 'whatwg-fetch'
import loadProcedureNames from 'api/loadProcedureNames'
import "@fontsource/fira-sans"
import "@fontsource/open-sans"
import "@fontsource/dm-sans"

if (typeof window.Proxy === 'undefined') {
  loadProcedureNames().then(() => {
    return import('./bootstrap/phase1')
  })
} else {
  loadProcedureNames()
  import('./bootstrap/phase1')
}
