import * as Sentry from '@sentry/react'
import { sentryDSN } from './env.js'

if (!import.meta.env.DEV && !window.SCORM_API && sentryDSN) {
  Sentry.init({
    environment: process.env.NODE_ENV,
    dsn: sentryDSN,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      'localhost',
      /^https:\/\/app.diggle.com/,
      /^https:\/\/staging.diggle.com/,
      /^https:\/\/api.diggle.com/,
      /^https:\/\/dev.diggle.com/,
    ],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
}
