import { setProcedureNames } from 'procedure-names'
import { apiUrl } from '../../env'

let timeout = 500

export default async function loadProcedureNames() {
  return fetch(`${apiUrl}/procedure/getProcedureNames`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(res => res.json())
    .then(procedureNames => {
      setProcedureNames(procedureNames)
    })
    .catch(e => {
      setTimeout(() => {
        throw e
      })

      return new Promise(resolve => {
        setTimeout(() => {
          if (timeout < 10 * 60 * 1000) {
            timeout *= 2
          }
          resolve(loadProcedureNames())
        }, timeout)
      })
    })
}
